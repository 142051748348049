import { Button, Input, Space, Table, Tag } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import AdminLayout from './AdminLayout'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { httpGetAdmin } from '../../services/http'

export default function AdminLLUsers() {
    var searchInputRef = useRef<Input>()
    const [llUsers, setLlUsers] = useState<any[]>([])
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')

    async function getUsers() {
        type usersData = {
            users: any[]
        }
        const res = await httpGetAdmin<usersData>('/ll-admin/ll-users')
        if (!res.data) return
        setLlUsers(
            res.data.users.sort((a, b) => {
                return (
                    Number(new Date(a.current_period_end).valueOf()) -
                    Number(new Date(b.current_period_end).valueOf())
                )
            })
        )
    }

    useEffect(() => {
        getUsers().catch(() => {})
    }, [])

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters) => {
        clearFilters()
        setSearchText('')
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        ;(searchInputRef as any).current = node
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false })
                            setSearchText(selectedKeys[0])
                            setSearchedColumn(dataIndex)
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInputRef?.current?.select(), 100)
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter // this works dunno why there is an error
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '15%',
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '15%',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Has paid',
            dataIndex: 'has_paid',
            key: 'has_paid',
            width: '10%',
            sorter: (a, b) => Number(a.has_paid) - Number(b.has_paid),
            render: (has_paid: boolean) => {
                return has_paid ? <Tag color="success">Yes</Tag> : <Tag color="error">No</Tag>
            },
        },
        {
            title: 'Email verified',
            dataIndex: 'email_verified',
            key: 'email_verified',
            width: '10%',
            sorter: (a, b) => Number(a.email_verified) - Number(b.email_verified),
            render: (email_verified: boolean) => {
                return email_verified ? <Tag color="success">Yes</Tag> : <Tag color="error">No</Tag>
            },
        },
        {
            title: 'Subscription reset',
            dataIndex: 'current_period_end',
            key: 'current_period_end',
            width: '15%',
            sorter: (a, b) =>
                Number(new Date(a.current_period_end).valueOf()) -
                Number(new Date(b.current_period_end).valueOf()),
            ellipsis: true,
            render: (current_period_end: Date | number) => {
                return current_period_end
            },
        },
        {
            title: 'Exemption periods',
            dataIndex: 'ex_count',
            key: 'ex_count',
            width: '7%',
            sorter: (a, b) => Number(a.ex_count) - Number(b.ex_count),
        },
        {
            title: 'DK periods',
            dataIndex: 'vacation_count',
            key: 'vacation_count',
            width: '7%',
            sorter: (a, b) => Number(a.vacation_count) - Number(b.vacation_count),
        },
        {
            title: 'Doc count',
            dataIndex: 'doc_count',
            key: 'doc_count',
            width: '7%',
            sorter: (a, b) => Number(a.doc_count) - Number(b.doc_count),
        },
        {
            title: 'Notes',
            dataIndex: 'user_notes',
            key: 'user_notes',
            width: '7%',
            sorter: (a, b) => Number(a.user_notes) - Number(b.user_notes),
        },
        {
            title: 'Roster periods',
            dataIndex: 'roster_period',
            key: 'roster_period',
            width: '7%',
            sorter: (a, b) => Number(a.roster_period) - Number(b.roster_period),
        },
    ]

    return (
        <AdminLayout>
            <Table
                pagination={false}
                columns={columns}
                dataSource={llUsers}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            window.open(`/ll-admin/ll-user/${record.id}`, '_blank')
                        }, // click row
                        onDoubleClick: (event) => {}, // double click row
                        onContextMenu: (event) => {}, // right button click row
                        onMouseEnter: (event) => {}, // mouse enter row
                        onMouseLeave: (event) => {}, // mouse leave row
                    }
                }}
            />
        </AdminLayout>
    )
}
